import type {EventHashedID, EventsFilters, EventsListLazyData, EventsReduxState, EventTimeFilterMap, EventVO} from '@Components/events/events.types';
import {EventSortBy, EventStatus, EventsViewType, EventTimeFilter} from '@Components/events/events.types';
import {appendFontStyleSheetToDOM, getFontStyleSheetPath} from '@Libraries/font-library';
import {getFontFamilyStyleString} from '@Utils/font.util';
import {generateIncrementalShadedColors} from '@Utils/color.util';
import type {ContactDetailsFormData, PageDetailsFormData} from '@Components/mystuff-events-home/mystuff-events-home.types';
import {EventsHomeIframePreviewHelper} from '@Components/mystuff-events-home/events-home-iframe-preview.class';
import {areDatesEqual, convertUTCTimeToGivenTimezone, getDateFromUnixTimestamp, getReadableMonth, getUnixTimestamp, getYearMonthDateFormat, hasDatedPassed, isBetweenDates, isFutureDate} from '@Utils/date.util';
import {LoadingStates} from '@Utils/loading.util';
import {getUniqueString} from '@Utils/string.util';
import {openEventPreviewModal} from '@Modals/event-preview-modal/event-preview-modal.library';
import type {RecurringEvent} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {RecurringType} from '@Components/event-wizard/recurring-event-selector/recurring-event-selector.types';
import {ALL_WEEKDAYS, Weekdays} from '@Components/event-wizard/week-day-selectors/week-day-selectors.types';
import {initSocialMediaProfileLinks} from '@Components/social-media-profile-links/social-media-profile-links-library';
import {getUserId} from '@Libraries/user.library';
import {isPAYGUser} from '@Utils/user.util';
import type {MediaTypes} from '@Components/event-wizard/event-wizard-image-video/event-wizard-image-video.types';
import {hideLoading, showLoading} from '@Libraries/loading-toast-library';
import {openConfirmDeleteModal} from '@Modals/confirm-delete-modal';
import {onAjaxError} from '@Utils/ajax.util';
import {noop} from '@Utils/general.util';
import {getTranslatedWeekdays} from '@Components/event-wizard/event-wizard-date-time-summary/event-wizard-date-time-summary.types';
import type {PlaceDetails} from '@Components/google-map/google-map.types';
import {cleanURIComponent} from '@Utils/url.util';

export const EVENTS_LAZY_BATCH_SIZE = 50;

export const EVENT_WIZARD_SOURCE_URL_PARAM = 'st';
export const EVENT_PAGE_SOURCE_URL_PARAM = 'st';

export const DEFAULT_EVENTS_STYLE_FONT_FAMILY = 'NunitoSansBold';

export interface DeleteEventFlowParams {
  eventHashedId: EventHashedID;
  escapedEventName: string;
  onDeleteSuccess: VoidFunction;
  doShowDeleteButtonLoader?: boolean;
}

export enum EventUpsellType {
  MORE_EVENTS,
  MORE_EVENTS_PREMIUM,
  REMOVE_WATERMARK,
  DESCRIPTION_REWRITE_AI,
  REGISTRATION_OPTIONS,
  RECURRING,
  NUM_GUESTS_LIMIT,
  VIEW_ANALYTICS,
  REMINDERS,
  SHARE_TEAM,
}

/**
 * query params appended to the wizard url
 * for example, for prefilling start date, we use query param 'sd'
 * ENSURE THIS IS THE SAME AS IN PHP SIDE. SEE "EventWizardURLMaker.php"
 */
export enum EventWizardPrefillValueQueryParam {
  START_DATE = 'sd',
  DESIGN_TEMPLATE_HID = 'tcph',
  DESIGN_TEMPLATE_URL = 'tcpu',
  DESIGN_TEMPLATE_SOURCE = 'tcps',
}

export enum EventWizardPrefillSource {
  DEFAULT,
  DUPLICATE,
  UPDATE,
  PREFILL_FROM_DESIGN,
  PREFILL_FROM_START_DATE_SELECTION,
}

export enum EventPageSource {
  DEFAULT,
  FROM_WIZARD_COMPLETION = 1,
  FOR_WIZARD_IFRAME = 2,
}

/**
 * starting the calendar from monday onwards for events specifically
 */
export const getTranslatedPluralWeekdaysForEvents = (): string[] => {
  return [
    window.i18next.t('pmwjs_calendar_mondays'),
    window.i18next.t('pmwjs_calendar_tuesdays'),
    window.i18next.t('pmwjs_calendar_wednesdays'),
    window.i18next.t('pmwjs_calendar_thursdays'),
    window.i18next.t('pmwjs_calendar_fridays'),
    window.i18next.t('pmwjs_calendar_saturdays'),
    window.i18next.t('pmwjs_calendar_sundays'),
  ];
};

export const isEventsFeatureEnabled = (): boolean => {
  return !!window.reactPageProps.isEventsFeatureEnabled;
};

export const isEventPromotionEnabled = (): boolean => {
  return !!window.reactPageProps.isEventPromotionEnabled;
};

export const getEventWizardURLWithPrefillSource = (prefillSource: EventWizardPrefillSource, prefillParamValue: Record<string, string> = {}, eventHashedId = ''): string => {
  const baseURI = eventHashedId ? `event/wizard/${eventHashedId}` : 'event/wizard';
  const url = new URL(window.PMW.util.site_url(baseURI));

  url.searchParams.append(EVENT_WIZARD_SOURCE_URL_PARAM, prefillSource.toString());

  Object.entries(prefillParamValue).forEach(([index, value]) => {
    url.searchParams.append(index, value);
  });
  return url.toString();
};

export const getURLToDuplicateEvent = (event: EventVO): string => {
  return getEventWizardURLWithPrefillSource(EventWizardPrefillSource.DUPLICATE, {}, event.hashedID);
};

export const getEventWizardURLWithDesignPrefill = (designHID: string, designURL: string, designSource: MediaTypes): string => {
  return getEventWizardURLWithPrefillSource(EventWizardPrefillSource.PREFILL_FROM_DESIGN, {
    [EventWizardPrefillValueQueryParam.DESIGN_TEMPLATE_HID.toString()]: designHID,
    [EventWizardPrefillValueQueryParam.DESIGN_TEMPLATE_URL.toString()]: designURL,
    [EventWizardPrefillValueQueryParam.DESIGN_TEMPLATE_SOURCE.toString()]: designSource.toString(),
  });
};

export const getEventWizardURLWithDatePrefill = (date: Date): string => {
  return getEventWizardURLWithPrefillSource(EventWizardPrefillSource.PREFILL_FROM_START_DATE_SELECTION, {
    [EventWizardPrefillValueQueryParam.START_DATE.toString()]: getYearMonthDateFormat(date),
  });
};

export const openEventWizardWithStartDatePrefill = (date: Date): void => {
  window.location.href = getEventWizardURLWithDatePrefill(date);
};

export const getEventPageURL = (event: EventVO): string => {
  return window.PMW.util.site_url(getBaseEventPageURL(event));
};

export const getEventPageURLWithSource = (source: EventPageSource, event: EventVO | undefined = undefined): string => {
  const baseURI = event ? getBaseEventPageURL(event) : 'e';
  const url = new URL(window.PMW.util.site_url(baseURI));

  if (areQueryParamsNotNeededForEventPageSource(source)) {
    return url.toString();
  }
  url.searchParams.append(EVENT_PAGE_SOURCE_URL_PARAM, source.toString());
  return url.toString();
};

export const getBaseEventPageURL = (event: EventVO): string => {
  const baseURL = 'e';

  const venue = getVenueSEOForEventPageURL(event.venue);
  const eventTypeName = event.type.name;

  const seoURI = cleanURIComponent(`${venue ? venue : ''}${eventTypeName ? `-${eventTypeName}` : ''}-${event.title}`);
  const siteStr = `${baseURL}/${seoURI}/${event.hashedID}`;

  return siteStr.toLowerCase();
};

const getVenueSEOForEventPageURL = (placeDetails: PlaceDetails | undefined): string => {
  let venue = 'online';

  if (placeDetails) {
    const city = placeDetails.city;
    const country = placeDetails.country;

    if (city && city.trim() !== '' && country && country.trim() !== '') {
      venue = `${city}-${country}`;
    } else if (city) {
      venue = city;
    } else if (country) {
      venue = country;
    }
  }
  return venue;
};

export const getEventEditURL = (event: EventVO): string => {
  return window.PMW.util.site_url(`event/wizard/${event.hashedID}`);
};

export const isCurrentUserEventCreator = (event: EventVO): boolean => {
  const currentUserId = getUserId();

  if (!currentUserId) {
    return false;
  }

  return isUserEventCreator(event, currentUserId);
};

export const isUserEventCreator = (event: EventVO, userId: number): boolean => {
  return event.creatorId === userId;
};

export const getEventsLazyDataBasedOnAppliedFilters = (state: EventsReduxState, eventsViewType: EventsViewType, eventFilters: EventsFilters): EventsListLazyData => {
  if (areEventFiltersInDefaultState(eventFilters)) {
    return state.eventsListDefaultLazyData;
  }
  return state.eventsListFilteredLazyData;
};

export const filterEventsByStatusAndSearch = (events: EventVO[], filters: EventsFilters): EventVO[] => {
  return events.filter((userEvent) => {
    return isEventIncludedInSearch(userEvent, filters.searchTerm) && filters.timeFilter[getEventTimeBasedStatus(userEvent)];
  });
};

export const filterEventsBySearch = (events: EventVO[], searchTerm: string): EventVO[] => {
  return events.filter((userEvent) => {
    return isEventIncludedInSearch(userEvent, searchTerm);
  });
};

export const sortEvents = (events: EventVO[], sortEventsBy: EventSortBy): EventVO[] => {
  if (sortEventsBy === EventSortBy.EVENT_START_DATE) {
    return events.sort((event1, event2) => {
      return sortEventsByDates(event1.time?.date_start ?? '', event2.time?.date_start ?? '');
    });
  }

  return events.sort((event1, event2) => {
    return sortEventsByDates(event1.lastModified, event2.lastModified);
  });
};

export const filterEvents = (events: EventVO[], filters: EventsFilters): EventVO[] => {
  return sortEvents(filterEventsByStatusAndSearch(events, filters), filters.sortType);
};

export const openUserEventPreviewDialog = (event: EventVO): void => {
  openEventPreviewModal({
    iframeTitle: event.title,
    iframeURL: getEventPageURL(event),
    event,
  });
};

export const openOnlineEventLink = (event: EventVO): void => {
  if (!event.venueOnline?.link || isPastEvent(event)) {
    return;
  }

  window.open(event.venueOnline.link, '_blank');
};

export const getUTCEventStartDate = (event: EventVO): Date => {
  return getDateFromUnixTimestamp(event.startDateTimestamp);
}

export const getUTCEventEndDate = (event: EventVO): Date => {
  return getDateFromUnixTimestamp(event.endDateTimestamp);
}

/**
 * event startDateTime is of format: 2025-03-24 07:00:00 this is in UTC time, we convert it to timezone time using timezone offset in format +02:00
 * @param event
 */
export const getTimezoneAdjustedEventStartDate = (event: EventVO): Date => {
  return convertUTCTimeToGivenTimezone(new Date(event.startDateTime), event.timezoneOffset ?? '+0:00');
}

export const getTimezoneAdjustedEventEndDate = (event: EventVO): Date => {
  return convertUTCTimeToGivenTimezone(new Date(event.endDateTime), event.timezoneOffset ?? '+0:00');
}

export const isOngoingEvent = (event: EventVO): boolean => {
  const eventStartDate = getUTCEventStartDate(event);
  const eventEndDate = getUTCEventEndDate(event);
  const currentDate = new Date();
  return isBetweenDates(currentDate, eventStartDate, eventEndDate);
};

export const isPastEvent = (event: EventVO): boolean => {
  return hasDatedPassed(getUTCEventEndDate(event));
};

export const isUpcomingEvent = (event: EventVO): boolean => {
  return isFutureDate(getUTCEventStartDate(event));
};

export const isRecurringEvent = (event: EventVO): boolean => {
  return !!event.recurring;
};

export const isDailyRecurringEvent = (recurringEventPattern?: RecurringEvent): boolean => {
  return recurringEventPattern?.recurringType === RecurringType.DAILY;
};

export const isMonthlyRecurringEvent = (recurringEventPattern?: RecurringEvent): boolean => {
  return recurringEventPattern?.recurringType === RecurringType.MONTHLY && !!recurringEventPattern.recurringMonthly;
};

export const isSingleDayWeeklyRecurringEvent = (recurringEventPattern?: RecurringEvent): boolean => {
  return recurringEventPattern?.recurringType === RecurringType.WEEKLY && recurringEventPattern.recurringWeeks?.length === 1;
};

export const isMultipleDayWeeklyRecurringEvent = (recurringEventPattern?: RecurringEvent): boolean => {
  return recurringEventPattern?.recurringType === RecurringType.WEEKLY && recurringEventPattern.recurringWeeks?.length > 1;
};

export const getWeekdayForSingleDayWeeklyRecurringEvent = (recurringEventPattern: RecurringEvent): string => {
  return getTranslatedWeekdays()[ALL_WEEKDAYS.indexOf(recurringEventPattern.recurringWeeks[0])];
};

export const getPluralWeekdayForSingleDayWeeklyRecurringEvent = (recurringEventPattern: RecurringEvent): string => {
  return getTranslatedPluralWeekdaysForEvents()[ALL_WEEKDAYS.indexOf(recurringEventPattern.recurringWeeks[0])];
};

export const getMonthlyRecurringEventWeekdayInfoString = (recurringEventPattern: RecurringEvent): string => {
  return `${recurringEventPattern.recurringMonthly.weekdayNum} ${getExtraShortWeekdayString(recurringEventPattern.recurringMonthly.weekday)}`;
};

export const doesEventSpanMultipleDays = (event: EventVO): boolean => {
  const eventStartDate = getUTCEventStartDate(event);
  const eventEndDate = getUTCEventEndDate(event);
  return !areDatesEqual(eventStartDate, eventEndDate);
};

export const getMultiDayFormattedEventDateText = (eventStartDate: Date, eventEndDate: Date): string => {
  return `${eventStartDate.getDate()} ${getReadableMonth(eventStartDate)} - ${eventEndDate.getDate()} ${getReadableMonth(eventEndDate)}`;
};

export const loadFontFamilyInIframe = (iframeDocument: Document, fontFamily: string): void => {
  const elements = iframeDocument.querySelectorAll('.js-custom-font-family-text');

  elements.forEach((el) => {
    el.classList.add('-loading');
  });

  appendFontStyleSheetToDOM(fontFamily, getFontStyleSheetPath(fontFamily), iframeDocument, () => {
    iframeDocument.fonts.ready
      .then(() => {
        loadAndApplyFontFamilyInIframe(iframeDocument, fontFamily, elements);
      })
      .catch(noop);
  });
};

export const loadAndApplyFontFamilyInIframe = (iframeDocument: Document, fontFamily: string, elementsToApplyFontOn: NodeListOf<Element>): void => {
  void iframeDocument.fonts.load(`16px '${fontFamily}'`).then(() => {
    // Apply the font family and remove the '-loading' class for all matching elements
    elementsToApplyFontOn.forEach((el) => {
      (el as HTMLHeadingElement | HTMLSpanElement).style.fontFamily = getFontFamilyStyleString(fontFamily);
      el.classList.remove('-loading');
    });
  });
};

export const applyStylesInEventsHomeIFrame = (pageDetailsForm: PageDetailsFormData, contactDetailsForm: ContactDetailsFormData, iframeClass: string): void => {
  const iframePreviewHelper = new EventsHomeIframePreviewHelper(iframeClass);
  iframePreviewHelper.setEventsHomeTitle(pageDetailsForm.title);
  iframePreviewHelper.setEventsHomeDescription(pageDetailsForm.description);
  iframePreviewHelper.toggleWatermark(pageDetailsForm.doRemoveWatermark);
  iframePreviewHelper.togglePastEvents(pageDetailsForm.doShowPastEvents);
  iframePreviewHelper.applyColorInIframe(pageDetailsForm.color, generateIncrementalShadedColors(pageDetailsForm.color)[1]);
  iframePreviewHelper.applyFontFamilyToHeading(pageDetailsForm.fontFamily);
  iframePreviewHelper.setEmailInIframe(contactDetailsForm.email);
  iframePreviewHelper.setPhoneNumberInIframe(contactDetailsForm.phoneNumber);
  iframePreviewHelper.setAllSocialLinksInIframe(contactDetailsForm.socialMediaProfilesInput);
  iframePreviewHelper.setImagesInIframe(pageDetailsForm.imageURL, pageDetailsForm.coverPhotoURL);
};

export const getDefaultLazyData = (loadingState: LoadingStates = LoadingStates.NOT_LOADED): EventsListLazyData => {
  return {
    ids: [],
    loadingState,
    loadMore: true,
  };
};

export const areEventFiltersInDefaultState = (filters: EventsFilters): boolean => {
  return filters.sortType === EventSortBy.EVENT_START_DATE && areAllEventStatusesSelectedInFilter(filters.timeFilter);
};

export const isMyEventsViewType = (eventsViewType: EventsViewType): boolean => {
  return eventsViewType === EventsViewType.DEFAULT;
};

export const isSharedWithMeEventsViewType = (eventsViewType: EventsViewType): boolean => {
  return eventsViewType === EventsViewType.SHARED_WITH_ME;
};

export const areAllEventStatusesSelectedInFilter = (statusFilter: EventTimeFilterMap): boolean => {
  return (
    Object.values(statusFilter).find((val) => {
      return !val;
    }) === undefined
  );
};

/**
 * Returns like 'Mo, Tu, We'
 * @param recurringPattern
 */
export const getExtraShortWeekdaysString = (recurringPattern?: RecurringEvent): string | null => {
  if (!recurringPattern || recurringPattern.recurringType !== RecurringType.WEEKLY) {
    return null;
  }

  const weekdaysArraySorted = [...recurringPattern.recurringWeeks];
  weekdaysArraySorted.sort((a, b) => {
    return ALL_WEEKDAYS.indexOf(a) - ALL_WEEKDAYS.indexOf(b);
  });

  const extraShortWeekdays = [];

  for (const weekday of weekdaysArraySorted) {
    extraShortWeekdays.push(getExtraShortWeekdayString(weekday));
  }

  return weekdaysArraySorted.map((weekday) => getExtraShortWeekdayString(weekday)).join(', ');
};

export const getExtraShortWeekdayString = (weekday: Weekdays): string => {
  if (weekday === Weekdays.MONDAY) {
    return window.i18next.t('pmwjs_calendar_mo');
  }

  if (weekday === Weekdays.TUESDAY) {
    return window.i18next.t('pmwjs_calendar_tu');
  }

  if (weekday === Weekdays.WEDNESDAY) {
    return window.i18next.t('pmwjs_calendar_we');
  }

  if (weekday === Weekdays.THURSDAY) {
    return window.i18next.t('pmwjs_calendar_th');
  }

  if (weekday === Weekdays.FRIDAY) {
    return window.i18next.t('pmwjs_calendar_fr');
  }

  if (weekday === Weekdays.SATURDAY) {
    return window.i18next.t('pmwjs_calendar_sa');
  }

  if (weekday === Weekdays.SUNDAY) {
    return window.i18next.t('pmwjs_calendar_su');
  }
  return '';
};

export const getRecurringEventDateText = (recurringEventPattern?: RecurringEvent): string => {
  if (!recurringEventPattern) {
    return '';
  }

  if (recurringEventPattern.recurringType === RecurringType.DAILY) {
    return window.i18next.t('pmwjs_daily');
  }

  if (recurringEventPattern.recurringType === RecurringType.MONTHLY && recurringEventPattern.recurringMonthly?.weekday) {
    const weekdayInd = ALL_WEEKDAYS.indexOf(recurringEventPattern?.recurringMonthly?.weekday);
    return window.i18next.t('pmwjs_monthly_x_weekday', {number: recurringEventPattern.recurringMonthly.weekdayNum, weekday: getTranslatedWeekdays()[weekdayInd]});
  }

  if (recurringEventPattern.recurringType === RecurringType.WEEKLY && recurringEventPattern.recurringWeeks?.length > 0) {
    if (recurringEventPattern.recurringWeeks.length > 1) {
      const extraShortWeekdaysString = getExtraShortWeekdaysString(recurringEventPattern);
      return window.i18next.t('pmwjs_every_weekdays', {weekdays: extraShortWeekdaysString});
    }
    const weekdayInd = ALL_WEEKDAYS.indexOf(recurringEventPattern.recurringWeeks[0]);
    return window.i18next.t('pmwjs_every_weekday', {weekday: getTranslatedWeekdays()[weekdayInd]});
  }
  return '';
};

/**
 * Base text: Daily, Weekly, Monthly, any text that describes the recurring event without the specific details of the time
 */
export const getRecurringEventDateBaseText = (recurringEventPattern?: RecurringEvent): string => {
  if (!recurringEventPattern) {
    return '';
  }

  if (isDailyRecurringEvent(recurringEventPattern)) {
    return window.i18next.t('pmwjs_daily');
  }

  if (isMonthlyRecurringEvent(recurringEventPattern)) {
    return window.i18next.t('pmwjs_monthly');
  }

  if (isSingleDayWeeklyRecurringEvent(recurringEventPattern)) {
    return getPluralWeekdayForSingleDayWeeklyRecurringEvent(recurringEventPattern);
  }

  if (isMultipleDayWeeklyRecurringEvent(recurringEventPattern)) {
    return window.i18next.t('pmwjs_weekly');
  }

  return '';
};

/**
 * constructs the descriptive part of a recuring events' date.
 * for example: Tu, We, Th , or: 1st Mo
 * @param recurringEventPattern
 */
export const getRecurringEventDateDescriptiveText = (recurringEventPattern?: RecurringEvent): string => {
  if (!recurringEventPattern) {
    return '';
  }

  if (isMonthlyRecurringEvent(recurringEventPattern)) {
    return getMonthlyRecurringEventWeekdayInfoString(recurringEventPattern);
  }

  if (isMultipleDayWeeklyRecurringEvent(recurringEventPattern)) {
    return getExtraShortWeekdaysString(recurringEventPattern) ?? '';
  }

  return '';
};

/**
 * constructs the full text for example: Weekly · Tu, We, Th
 */
export const getRecurringEventTextWithDetails = (recurringEventPattern?: RecurringEvent): string => {
  const baseText = getRecurringEventDateBaseText(recurringEventPattern);
  const descriptiveText = getRecurringEventDateDescriptiveText(recurringEventPattern);

  if (!descriptiveText) {
    return baseText;
  }

  return `${baseText} · ${descriptiveText}`;
};

const doDeleteEvent = async (eventHashedId: EventHashedID, onDeleteSuccess: VoidFunction): Promise<void> => {
  showLoading('deletingEvent', {
    text: window.i18next.t('pmwjs_deleting'),
  });

  try {
    await window.PMW.writeLocal('event/deleteEvent', {
      event_hid: eventHashedId,
    });
  } catch (err) {
    onAjaxError(err);
    return;
  }

  onDeleteSuccess();
  hideLoading('deletingEvent');
};

export const startDeleteEventFlow = ({eventHashedId, escapedEventName, onDeleteSuccess, doShowDeleteButtonLoader = false}: DeleteEventFlowParams): void => {
  openConfirmDeleteModal({
    text: window.i18next.t('pmwjs_are_you_sure_you_want_to_delete_event', {eventName: escapedEventName}),
    title: window.i18next.t('pmwjs_delete_event?'),
    showBtnDeletingState: doShowDeleteButtonLoader,
    onDeleteConfirmation(): Promise<void> | void {
      return doDeleteEvent(eventHashedId, onDeleteSuccess);
    },
  });
};

export const getDummyEventItem = (): EventVO => {
  return {
    hashedID: getUniqueString(),
    title: '',
    description: '',
    plainTextDescription: '',
    endDateTime: '',
    startDateTime: '',
    startDateTimestamp: getUnixTimestamp(new Date()),
    endDateTimestamp: getUnixTimestamp(new Date()),
    numRegistrations: 0,
    showCountdown: false,
    isRemoveWatermark: false,
    isShared: false,
    isPast: false,
    tzcode: '',
    registrationFields: {
      email: false,
      name: false,
      phone: false,
      registerBtnText: '',
    },
    organiser: {
      name: '',
      email: '',
      profilePic: '',
      isProfilePicturePrefilled: false,
      socialMediaLinks: initSocialMediaProfileLinks(),
    },
    status: EventStatus.DRAFT,
    type: {
      hashedID: '',
      name: '',
    },
    lastModified: '',
    style: {
      fontFamily: '',
      colors: {
        primary: '',
        light: '',
        lighter: '',
        dark: '',
        darker: '',
      },
    },
    allowRegistrations: false,
    creatorId: -1,
    shortTimezone: 'PKT',
    timezoneOffset: '+5:00'
  };
};

export const getSortedEventsBasedOnLiveStatus = (eventsList: EventVO[]): EventVO[] => {
  return eventsList.sort((event1, event2) => {
    const isEvent1Ongoing = isOngoingEvent(event1);
    const isEvent2Ongoing = isOngoingEvent(event2);
    if (isEvent1Ongoing && !isEvent2Ongoing) {
      return -1;
    }

    if (isEvent2Ongoing && !isEvent1Ongoing) {
      return 1;
    }

    return 0;
  });
};

export const openEventsUpsellDialog = (upsellType: EventUpsellType, onDialogLoaded = noop): void => {
  switch (upsellType) {
    case EventUpsellType.MORE_EVENTS:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_MORE_EVENTS, onDialogLoaded);
      return;
    case EventUpsellType.MORE_EVENTS_PREMIUM:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_PLUS_ONLY_FEATURE_NAME_MORE_EVENTS, onDialogLoaded);
      return;

    case EventUpsellType.REMOVE_WATERMARK:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_PLUS_ONLY_FEATURE_EVENTS_REMOVE_WATERMARK, onDialogLoaded);
      return;

    case EventUpsellType.DESCRIPTION_REWRITE_AI:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_EVENTS_AI_WRITER, onDialogLoaded);
      return;

    case EventUpsellType.REGISTRATION_OPTIONS:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_EVENTS_GUESTS_INFORMATION, onDialogLoaded);
      return;

    case EventUpsellType.RECURRING:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_PLUS_ONLY_FEATURE_NAME_RECURRING_EVENTS, onDialogLoaded);
      return;

    case EventUpsellType.NUM_GUESTS_LIMIT:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_EVENTS_LIMITED_REGISTRATONS, onDialogLoaded);
      return;

    case EventUpsellType.VIEW_ANALYTICS:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_EVENTS_VIEW_ANALYTICS, onDialogLoaded);
      return;

    case EventUpsellType.REMINDERS:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_EVENTS_REMINDERS, onDialogLoaded);
      return;

    case EventUpsellType.SHARE_TEAM:
      window.PMW.showPremiumOnlyFeatureDialog(window.PMW.PREMIUM_ONLY_FEATURE_NAME_TEAMS, onDialogLoaded);
      return;
  }
};

export const openUpsellDialogForEventsLimitReached = (onDialogLoaded = noop): void => {
  openEventsUpsellDialog(isPAYGUser() ? EventUpsellType.MORE_EVENTS : EventUpsellType.MORE_EVENTS_PREMIUM, onDialogLoaded);
};

const sortEventsByDates = (date1: string, date2: string): number => {
  return new Date(date2).getTime() - new Date(date1).getTime();
};

const isEventIncludedInSearch = (userEvent: EventVO, searchTerm: string): boolean => {
  return userEvent.title.toLowerCase().includes(searchTerm.toLowerCase());
};

/**
 *
 */
const getEventTimeBasedStatus = (userEvent: EventVO): EventTimeFilter => {
  if (isOngoingEvent(userEvent)) {
    return EventTimeFilter.LIVE;
  }

  if (isUpcomingEvent(userEvent)) {
    return EventTimeFilter.UPCOMING;
  }

  if (isPastEvent(userEvent)) {
    return EventTimeFilter.PAST;
  }

  return EventTimeFilter.PAST;
};

const areQueryParamsNotNeededForEventPageSource = (prefillSource: EventPageSource): boolean => {
  return prefillSource === EventPageSource.DEFAULT;
};
